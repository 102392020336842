<template>
  <div class="bottom-scene-list">
    <!-- 场景列表 -->
    <div class="scene-panel">
      <div class="scene-list">
        <el-tabs v-model="activeScene" type="border-card">
          <el-tab-pane v-for="item in $store.state.sceneList" :key="item.m_id" :index="item.m_id" :name="String(item.m_id)">
            <span slot="label" class="table-slot">
              {{ item.title }}
              <div class="more-tool">
                <el-dropdown :hide-on-click="false" placement="top" @command="handleCommand(item.m_id, $event)">
                  <span class="el-dropdown-link">
                     <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="edit">重命名</el-dropdown-item>
                    <el-dropdown-item command="delete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </span>
            <div class="camera-list">
              <swiper class="swiper" :options="swiperOption">
                <swiper-slide>
                  <div class="item">
                    <div class="add-new" @click="addNewCamera(item.m_id)">
                      <i class="el-icon-plus"></i>
                    </div>
                    <div class="item-name">添加机位</div>
                  </div>
                </swiper-slide>
                <template v-for="childItem in $store.state.cameraList">
                  <swiper-slide v-if="childItem.tid_scene === item.m_id" :key="childItem.m_id" :index="childItem.m_id">
                    <div :class="[ String(childItem.m_id) === activeCamera ? 'active' : '',  'item']" @click="changeCameraItem(childItem)">
                      <div class="preimg">
                        <el-image style="width: 100%; height: 80px" :src="childItem.img" fit="cover"></el-image>
                        <div class="tools">
                          <el-dropdown placement="top" @command="handleCameraCommand(childItem.m_id, $event)">
                        <span class="el-dropdown-link">
                           <i class="el-icon-more"></i>
                        </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item command="edit">编辑</el-dropdown-item>
                              <el-dropdown-item command="delete">删除</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>
                      <div class="item-name" :title="childItem.title">{{childItem.title}}</div>
                    </div>
                  </swiper-slide>
                </template>
              </swiper>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="add-group" @click="addSceneVisible = true">
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <!-- 添加场景弹窗 -->
      <el-dialog title="添加场景" :visible.sync="addSceneVisible" width="400px" :before-close="addSceneClose">
        <div class="form-content">
          <el-form :model="addSceneForm" :rules="addSceneRule" ref="addSceneRef" label-width="100px" class="form-panel">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="addSceneForm.sort" :min="0" :max="99999" label="数字越大越靠前"></el-input-number>
            </el-form-item>
            <el-form-item label="场景名称" prop="title">
              <el-input v-model="addSceneForm.title"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="addSceneVisible = false">取 消</el-button>
        <el-button type="primary" @click="addScene('addSceneRef')">添加</el-button>
      </span>
      </el-dialog>
      <!-- 编辑场景弹窗 -->
      <el-dialog title="编辑场景" :visible.sync="editSceneVisible" width="30%" :before-close="editSceneClose">
        <div class="form-content">
          <el-form :model="editSceneForm" :rules="editSceneRule" ref="editSceneRef" label-width="100px" class="form-panel">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="editSceneForm.sort" :min="0" :max="99999" label="数字越大越靠前"></el-input-number>
            </el-form-item>
            <el-form-item label="场景名称" prop="title">
              <el-input v-model="editSceneForm.title"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="editSceneVisible = false">取 消</el-button>
        <el-button type="primary" @click="editScene('editSceneRef')">修  改</el-button>
      </span>
      </el-dialog>
      <!-- 编辑新机位 -->
      <el-dialog title="编辑新机位" :visible.sync="editCameraVisible" width="600px" :before-close="editCameraClose">
        <div class="form-content">
          <el-form :model="editCameraForm" :rules="editCameraRule" ref="editCameraRef" label-width="100px" class="form-panel" label-position="top">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="editCameraForm.sort" :min="0" :max="99999" label="数字越大越靠前"></el-input-number>
            </el-form-item>
            <el-form-item label="机位镜头名称" prop="title">
              <el-input v-model="editCameraForm.title"></el-input>
            </el-form-item>
            <el-form-item label="镜头缩略图" prop="camera_img">
              <upload :url="editCameraForm.img" :filed="'img'" :max-num="1" @checked-files="checkedEditFiles" @del-select="delEditSelect"></upload>
            </el-form-item>
            <el-form-item label="初始镜头类型" prop="type">
              <el-radio-group v-model="editCameraForm.type">
                <el-radio :label="1">已上传视频文件</el-radio>
                <el-radio :label="2">直播链接</el-radio>
                <el-radio :label="3">站外视频链接</el-radio>
                <el-radio :label="4">已上传全景图</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="请选择已上传视频文件" prop="online_video" v-if="editCameraForm.type === 1">
              <upload :url="editCameraForm.url" :filed="'url'" :max-num="1" @checked-files="checkedEditFiles" @del-select="delEditSelect"></upload>
            </el-form-item>
            <el-form-item label="请填写直播链接" prop="url" v-if="editCameraForm.type === 2">
              <el-input v-model="editCameraForm.url"></el-input>
            </el-form-item>
            <el-form-item label="请填写站外视频链接" prop="url" v-if="editCameraForm.type === 3">
              <el-input v-model="editCameraForm.url"></el-input>
            </el-form-item>
            <el-form-item label="请选择已上传全景图" prop="url" v-if="editCameraForm.type === 4">
              <upload :url="editCameraForm.url" :filed="'url'" :max-num="1" @checked-files="checkedEditFiles" @del-select="delEditSelect"></upload>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="editCameraVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditCamera('editCameraRef')">修 改</el-button>
      </span>
      </el-dialog>
      <!-- 添加新机位 -->
      <el-dialog title="添加新机位" :visible.sync="addCameraVisible" width="700px" :before-close="addCameraClose">
        <div class="form-content">
          <el-form :model="addCameraForm" :rules="addCameraRule" ref="addCameraRef" label-width="100px" class="form-panel" label-position="top">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="addCameraForm.sort" :min="0" :max="99999" label="数字越大越靠前"></el-input-number>
            </el-form-item>
            <el-form-item label="机位镜头名称" prop="title">
              <el-input v-model="addCameraForm.title"></el-input>
            </el-form-item>
            <el-form-item label="镜头缩略图" prop="camera_img">
              <upload :url="addCameraForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
            </el-form-item>
            <el-form-item label="初始镜头类型" prop="type">
              <el-radio-group v-model="addCameraForm.type">
                <el-radio :label="1">已上传视频文件</el-radio>
                <el-radio :label="2">直播链接</el-radio>
                <el-radio :label="3">站外视频链接</el-radio>
                <el-radio :label="4">已上传全景图</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="请选择已上传视频文件" prop="online_video" v-if="addCameraForm.type === 1">
              <upload :url="addCameraForm.url" :filed="'url'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
            </el-form-item>
            <el-form-item label="请填写直播链接" prop="url" v-if="addCameraForm.type === 2">
              <el-input v-model="addCameraForm.url"></el-input>
            </el-form-item>
            <el-form-item label="请填写站外视频链接" prop="url" v-if="addCameraForm.type === 3">
              <el-input v-model="addCameraForm.url"></el-input>
            </el-form-item>
            <el-form-item label="请选择已上传全景图" prop="url" v-if="addCameraForm.type === 4">
              <upload :url="addCameraForm.url" :filed="'url'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="addCameraVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAddCamera('addCameraRef')">添加</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
export default {
  name: 'BottomSceneList',
  components: {
    Upload
  },
  data () {
    return {
      sceneList: [],
      cameraList: [],
      hotsList: [],
      // 当前激活的场景
      activeScene: '',
      // 当前激活的镜头
      activeCamera: '',
      // swiper 配置
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        observer: true,
        observeParents: true,
        observeSlideChildren: true
      },
      // 添加场景弹窗
      addSceneVisible: false,
      // 添加弹窗表单
      addSceneForm: {
        m_id: '',
        sort: 0,
        title: ''
      },
      // 添加场景规则
      addSceneRule: {
        title: [
          { required: true, message: '请填写场景名称', trigger: 'blur' },
          { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
        ]
      },
      // 编辑场景弹窗
      editSceneVisible: false,
      editSceneForm: {
        m_id: '',
        sort: 0,
        title: ''
      },
      // 编辑场景规则
      editSceneRule: {
        title: [
          { required: true, message: '请填写场景名称', trigger: 'blur' },
          { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
        ]
      },
      // 添加新机位弹窗
      addCameraVisible: false,
      addCameraForm: {
        tid: '',
        sort: 0,
        title: '',
        img: '',
        type: 1,
        url: ''
      },
      // 添加新机位镜头规则
      addCameraRule: {
        title: [
          { required: true, message: '请填写机位镜头名称', trigger: 'blur' },
          { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传镜头缩略图', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择初始镜头类型', trigger: 'change' }
        ]
      },
      // 编辑新机位弹窗
      editCameraVisible: false,
      editCameraForm: {
        m_id: 0,
        tid: 0,
        sort: 0,
        title: '',
        img: '',
        type: 1,
        url: ''
      },
      // 添加新机位镜头规则
      editCameraRule: {
        title: [
          { required: true, message: '请填写机位镜头名称', trigger: 'blur' },
          { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传镜头缩略图', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择初始镜头类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.componInit()
  },
  methods: {
    // 点击下方场景中的机位，切换画面
    changeCameraItem (item) {
      this.activeScene = String(item.tid_scene)
      this.activeCamera = String(item.m_id)
      this.$emit('updateCamera', item)
    },
    // 初始化
    componInit () {
      // 将vuex中当前项目m_id填充到表单中
      this.addSceneForm.m_id = this.$store.state.panoramaInfo.m_id
      // 获取当前显示的场景和机位ID
      if (this.$store.state.sceneList.length > 0) {
        this.activeScene = this.$store.state.sceneList[0].m_id
        // 查找归属该场景下的镜头
        const activeCameraIndex = this.$store.state.cameraList.findIndex(cameraItem => cameraItem.tid_scene === this.$store.state.sceneList[0].m_id)
        if (activeCameraIndex >= 0) {
          this.activeCamera = this.$store.state.cameraList[activeCameraIndex].m_id
        }
      }
    },
    // 添加场景
    addScene (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/panorama-add-scene', this.addSceneForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            await this.getProjectInfo()
            this.addSceneVisible = false
          } else {
            this.$message.error(res.msg)
          }
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    // 修改场景
    editScene (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/panorama-add-scene', this.editSceneForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            await this.getProjectInfo()
            this.editSceneVisible = false
          } else {
            this.$message.error(res.msg)
          }
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    // 点击添加新机位按钮
    addNewCamera (tid) {
      this.addCameraForm.tid = tid
      this.addCameraVisible = true
    },
    // 提交添加新机位表单
    submitAddCamera (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.addCameraForm.img === '') {
            this.$message.error('请上传镜头缩略图')
            return false
          }
          if (this.addCameraForm.url === '') {
            this.$message.error('请填写或上传对应参数')
            return false
          }
          const { data: res } = await this.$http.post('/panorama-add-camera', this.addCameraForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            await this.getProjectInfo()
            this.$refs.addCameraRef.resetFields()
            this.addCameraVisible = false
          } else {
            this.$message.error(res.msg)
          }
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    // 提交修改机位表单
    submitEditCamera (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.editCameraForm.img === '') {
            this.$message.error('请上传镜头缩略图')
            return false
          }
          if (this.editCameraForm.url === '') {
            this.$message.error('请填写或上传对应参数')
            return false
          }
          const { data: res } = await this.$http.put('/panorama-add-camera', this.editCameraForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            await this.getProjectInfo()
            this.$refs.editCameraRef.resetFields()
            this.editCameraVisible = false
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    // 关闭添加场景弹窗
    addSceneClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.addSceneRef.resetFields()
          done()
        })
        .catch(_ => {})
    },
    editSceneClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.editSceneRef.resetFields()
          done()
        })
        .catch(_ => {})
    },
    // 关闭添加新机位弹窗
    addCameraClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.addCameraRef.resetFields()
          done()
        })
        .catch(_ => {})
    },
    // 关闭编辑新机位弹窗
    editCameraClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.editCameraRef.resetFields()
          done()
        })
        .catch(_ => {})
    },
    // 场景的编辑和删除
    async handleCommand (mid, command) {
      // 发起编辑请求
      if (command === 'edit') {
        // 1. 获取该场景信息
        const { data: res } = await this.$http.get('/panorama-scene-item-info', { params: { mid: mid } })
        if (res.status === 200) {
          this.editSceneForm.m_id = res.data.m_id
          this.editSceneForm.sort = res.data.sort
          this.editSceneForm.title = res.data.title
          // 显示弹窗
          this.editSceneVisible = true
        } else {
          this.$message.error(res.msg)
        }
      } else if (command === 'delete') {
        // 删除请求
        const { data: res } = await this.$http.delete('/panorama-add-scene', { params: { mid: mid } })
        if (res.status === 200) {
          this.$message.success(res.msg)
          await this.getProjectInfo()
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    // 镜头的编辑和删除
    async handleCameraCommand (mid, command) {
      // 发起编辑请求
      if (command === 'edit') {
        // 1. 获取该场景信息
        const { data: res } = await this.$http.get('/panorama-add-camera', { params: { mid: mid } })
        if (res.status === 200) {
          this.editCameraForm.m_id = res.data.m_id
          this.editCameraForm.sort = res.data.sort
          this.editCameraForm.title = res.data.title
          this.editCameraForm.img = res.data.img
          this.editCameraForm.type = res.data.type
          this.editCameraForm.url = res.data.url
          // 显示弹窗
          this.editCameraVisible = true
        } else {
          this.$message.error(res.msg)
        }
      } else if (command === 'delete') {
        // 删除请求
        const { data: res } = await this.$http.delete('/panorama-add-camera', { params: { mid: mid } })
        if (res.status === 200) {
          this.$message.success(res.msg)
          await this.getProjectInfo()
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.addCameraForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addCameraForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addCameraForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addCameraForm[field].splice(checkedIndex, 1)
      }
    },
    // 编辑上传组件返回值
    checkedEditFiles (data) {
      this.editCameraForm[data.filed] = data.url
    },
    // 编辑删除已选择文件
    delEditSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editCameraForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editCameraForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editCameraForm[field].splice(checkedIndex, 1)
      }
    },
    // 获取项目信息
    async getProjectInfo () {
      const { data: res } = await this.$http.get('/panorama-project', { params: { mid: this.$route.params.project_id } })
      if (res.status === 200) {
        this.$store.commit('updatePanoramaAll', res.data.projectInfo)
        this.$store.commit('updateSceneList', res.data.sceneList)
        this.$store.commit('updateCameraList', res.data.cameraList)
        this.$store.commit('updateHotsList', res.data.hotsList)
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  mounted () {
    // bus通信
    this.$bus.$on('bottomSceneChangeActive', (data) => {
      // console.log('1.   activeScene:' + data.activeScene + '---activeCamera:  ' + data.activeCamera)
      this.activeScene = data.activeScene
      this.activeCamera = data.activeCamera
      const activeCameraIndex = this.$store.state.cameraList.findIndex(item => {
        return item.m_id === this.activeCamera
      })
      this.$emit('updateCamera', this.$store.state.cameraList[activeCameraIndex])
      // console.log('activeScene:' + data.activeScene + '---activeCamera:  ' + data.activeCamera)
    })
  },
  computed: {
    watchSceneList () {
      return this.$store.state.sceneList
    }
  },
  watch: {
    watchSceneList () {
      this.componInit()
    }
  },
  beforeDestroy () {
    this.$bus.$off('bottomSceneChangeActive')
  }
}
</script>

<style scoped>
.bottom-scene-list{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.scene-list{
  height: 180px;
  padding: 10px;
  position: relative;
}
.scene-list .el-tabs{
  height: 100%;
  border: 0;
}
.scene-list /deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  color: #ff9900;
}
.scene-list /deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover{
  color: #ff9900;
}
.scene-list /deep/.el-tabs__item{
  padding: 0 20px;
  height: 28px;
  box-sizing: border-box;
  line-height: 28px;
  display: inline-block;
  list-style: none;
  font-size: 13px;
  font-weight: 400;
  color: #bbbec4;
  position: relative;
}
.scene-list /deep/.el-tabs__active-bar{
}
.scene-list /deep/.el-tabs__header{
  background-color: #1c2438;
  width: 100%;
  padding-right: 30px;
}
.scene-list /deep/.el-tabs__nav-prev{
  line-height: 28px;
}
.scene-list /deep/.el-tabs__nav-next, .el-tabs__nav-prev{
  line-height: 28px;
}
.scene-list .table-slot {
  display: flex;
}
.scene-list .table-slot .more-tool{
  margin-left: 8px;
}
.scene-list .add-group{
  position: absolute;
  top: 13px;
  right: 13px;
  color: #1c2438;
  width: 20px;
  height: 20px;
  background-color: #bbbec4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}
.camera-list .swiper{}
.camera-list .swiper /deep/.swiper-slide{
  width: 80px;
}
.camera-list .swiper .swiper-slide .item{
  position: relative;
  cursor: pointer;
}
.camera-list .swiper .swiper-slide .item .preimg{
  position: relative;
}
.camera-list .swiper .swiper-slide .item.active{
  color: #ff9900;
  font-weight: bold;
}
.camera-list .swiper .swiper-slide .item.active .preimg{
  border: 2px solid #ff9900;
  background-color: #ff9900;
}
.camera-list .swiper .swiper-slide .item .add-new{
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #1c2438;
  border-radius: 6px;
  cursor: pointer;
  opacity: 0.4;
}
.camera-list .swiper .swiper-slide .item .add-new:hover{
  border: 1px dashed #ff9900;
  opacity: 1;
}
.camera-list .swiper .swiper-slide .item .item-name{
  font-size: 13px;
  text-align: center;
  margin-top: 6px;
  overflow:hidden;
  white-space:nowrap; /*不允许换行*/
  text-overflow:ellipsis;/*超出部分省略号显示*/
}
.camera-list .swiper .swiper-slide .item .preimg .tools{
  position: absolute;
  bottom: 8px;
  right: 4px;
  background-color: #ff9900;
  padding: 0 4px;
  border-radius: 4px;
  color: #FFFFFF;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/deep/.el-dialog__body{
  background-color: #1c2438;
}
/deep/.el-form-item__label{
  text-align: left;
}
</style>
